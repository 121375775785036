import * as React from "react"
import Layout from "../../components/layout/layout.en"
import Seo from "../../components/seo"
import { Link } from "gatsby"


const NotFoundPage = () => {
    return (
  <Layout>
    <Seo title="Error 404 - We couldn't find that web page" />
    <div className="container">
      <h1>Error 404 - We couldn't find that web page</h1>
      <p>We're sorry you ended up here. Sometimes a page gets moved or deleted, but hopefully we can help you find what you're looking for. What next?</p>
      <ul>
					<li>
            <Link to="/">
              Return to the home page
            </Link>
          </li> 
			</ul> 
    </div>
  </Layout>
)
    }

export default NotFoundPage